<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 bg-white">
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ $t("DetallesUsuario") }}
      </h2>
    </div>
    <b-row v-if="userPrepare">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-3">
            <b-col lg="6" md="8" class="d-flex align-items-center">
              <div class="pr-2">
                <b-avatar size="4rem" :src="user.avatar" />
              </div>
              <div>
                <h4 class="m-0 font-weight-bolder">
                  {{ user.name }} {{ user.surname }}
                </h4>
                <p class="m-0">
                  <span v-for="(c, i) in user.clients" :key="`client-${c.name}-${c.id}`"><span v-if="i !== 0">,
                    </span>{{
          c.name }}</span>
                </p>
              </div>
            </b-col>
            <b-col lg="6" class="d-flex align-items-center justify-content-end gap-1">
              <!-- aquí los controles -->
              <div class="text-center">
                <p class="mb-50 title-style text-uppercase">
                  {{ $t("AccesoApp") }}
                </p>
                <b-form-checkbox v-model="isChecked" switch inline :disabled="user.app_access === 1"
                  @click.native.prevent="changeAccess(user)"></b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <b-row class="position-relative">
            <!-- aquí los datos del user -->
            <b-col lg="4" md="6" v-if="user.email">
              <p class="mb-50 title-style">
                {{ $t("EMAIL") }}
              </p>
              <p class="font-weight-bolder">
                {{ user.email }}
              </p>
            </b-col>
            <b-col lg="4" md="6" v-if="user.phone">
              <p class="mb-50 title-style">
                {{ $t("TELEFONO") }}
              </p>
              <p class="font-weight-bolder">
                {{ user.phone }}
              </p>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-link :to="{ name: 'editContactPerson', params: { id: user.id } }"
                class="d-inline-block text-dark pt-2">
                <span class="">{{ $t("Editar") }}</span>
                <span class="pl-1"><feather-icon icon="Edit2Icon" /></span>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCard,
  BLink,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ListAssets from "@/views/installation/ListTable";
import ListTasks from "@/views/tasks/ListTable";

export default {
  components: {
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BLink,
    ListAssets,
    ListTasks,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      companies: "",
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      changePassword: false,
      collapseInfo: false,
      isChecked: false,
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      user: "users/getUser",
      checkJourney: "users/getCheckJourney",
      role: "auth/getRole",
    }),
  },
  methods: {
    ...mapActions({
      getUser: "users/getUser",
      app_access: "users/app_access",
    }),
    ...mapMutations({
      setSelectDepartments: "departments/setSelectDepartments",
    }),
    changeAccess(item) {
      if (item.app_access !== 1) {
        this.$bvModal
          .msgBoxConfirm(this.$t("DarAccesoApp", { nombre: item.name }), {
            bodyClass: "bodyEdit",
            footerClass: "footerDelete",
            okVariant: "primary",
            okTitle: this.$t("Confirmar"),
            cancelTitle: this.$t("Cancelar"),
            cancelVariant: "outline-primary",
            centered: true,
          })
          .then((value) => {
            if (value === true) {
              this.app_access(item.id);
              this.isChecked = true;
            } else {
              this.isChecked = false;
            }
          });
      }
    },
  },

  async created() {
    await this.getUser(this.$route.params.id);
    if (this.user.app_access === 1) this.isChecked = true;

    this.userPrepare = true;
  },
  destroyed() {
    this.$store.commit("users/setUser", {});
  },
};
</script>
